import { gql } from '@apollo/client';

export const localTypeDefs = gql`
  type TestLocalState {
    state1: Int!
    state2: Boolean!
  }

  type CartItem {
    id: ID
    name: String
  }

  extend type AssemblyProject {
    testLocal: Boolean!
  }

  extend type AssemblyProject {
    localState: TestLocalState
    cartItems: [CartItem]
  }

  extend type Media {
    percentage: Int
    progressText: String
  }

  extend type Query {
    cartItems(id: String): [CartItem]
    isOpenListDict: Boolean
    currentAuthorId: ID
  }

  extend type Mutation {
    cartItem(id: ID, name: String): CartItem
  }

  directive @client(always: Boolean) on FIELD
  directive @export(as: String) on FIELD
`;
