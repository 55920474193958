import { isOpenListDictVar } from '../vars';
import { AssemblyProject } from './AssemblyProject';
import { Media } from './Media';
import type { TypedTypePolicies } from '@root/graphql/generated/apolloHelpers';
import { IS_SERVER } from '@root/utils';

const typePolicies: TypedTypePolicies = {
  AssemblyProject,
  Media,
  Query: {
    fields: {
      // currentAuthorId: {
      //   read() {
      //     return 100;
      //   },
      // },
      cartItems: {
        read(existing, options) {
          // if (!options.storage.var) {
          //   console.log('Vao');
          //   options.storage.var = cartItemsVar;
          // }
          // console.log('query read', { existing, options });
          // const data = options.storage;
          // const merged: any[] = existing ? existing.slice(0) : [];
          // const authorNameToIndex: Record<string, number> = Object.create(null);

          // if (existing) {
          //   existing.forEach((author: any, index: any) => {
          //     console.log({ author }, options.readField<string>('name', author));
          //     authorNameToIndex[options.readField<string>('name', author) as any] = index;
          //   });
          // }

          // console.log({ authorNameToIndex });
          // console.log(options.storage.var);
          // return options.storage.var();
          return existing;
          // return existing; //(cartItemsVar && cartItemsVar());
        },
        // merge(existing, incoming, options) {
        //   console.log('query merge', { existing, incoming, options });
        //   if (!options.storage.var) return;
        //   if (options.storage.var) {
        //     options.storage.var([...(existing || []), ...incoming]);
        //   }

        //   // return [...(existing || []), ...incoming];
        //   return options.storage.var();
        // },
      },
      isOpenListDict: {
        read() {
          return IS_SERVER ? null : isOpenListDictVar();
        },
      },
    },
  },
};

export default typePolicies;
