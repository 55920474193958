import { TypePolicy } from '@apollo/client';
import type { StrictTypedTypePolicies } from '@root/graphql/generated/apolloHelpers';

type StrictTypedTypePolicy = StrictTypedTypePolicies['AssemblyProject'] & TypePolicy;

const AssemblyProject: StrictTypedTypePolicy = {
  fields: {
    cartItems: {
      read(existing, { cache }) {
        // existing come from resolver return or write directly to cache
        return existing;
      },
      // merge(existing, incoming, options) {
      //   return options.mergeObjects(existing, incoming);
      // },
    },
    testLocal: {
      read(_, { variables }) {
        return true;
      },
    },
    localState: {
      read(_, options) {
        return { state1: 2, state2: false };
      },
    },
  },
};

export { AssemblyProject };
