import type { Resolvers, InMemoryCache } from '@apollo/client';
import { GetCartItemsDocument, MediaStatus } from '@root/graphql/generated/operations';

const resolvers: Resolvers | Resolvers[] = {
  AssemblyProject: {
    cartItems: (root, variables, { cache, getCacheKey }) => {
      const data = cache.readQuery({ query: GetCartItemsDocument });

      const cartItems = [{ __typename: 'CartItem', id: 0, name: 'cartItem resolvers AssemblyProject field' }];

      return cartItems;
    },
  },
  Media: {
    percentage: (root, variables, { cache, getCacheKey }) => {
      const cacheIdentifier = getCacheKey({ __typename: 'Media', id: root.id });
      return [MediaStatus.Ready, MediaStatus.Transcribed].includes(root.status) ? 100 : 0; // init value
    },
    progressText: (root, variables, { cache, getCacheKey }) => {
      // const cacheIdentifier = getCacheKey({ __typename: 'Media', id: root.id });
      return '';
    },
  },
  Query: {
    cartItems: (root, variables, { cache, getCacheKey }) => {
      const cartItems = [{ __typename: 'CartItem', id: 1, name: 'cartItem resolvers query field' }];

      return cartItems;
    },
  },
  Mutation: {
    cartItem: (root, variables, { cache }) => {
      const cacheIdentifier = cache.identify({
        __typename: 'CartItem',
        id: variables.id,
      });

      variables.__typename = 'CartItem';
      cache.updateQuery(
        {
          query: GetCartItemsDocument,
        },
        (data: any) => {
          console.log({ data });
          return { cartItems: [...data.cartItems, variables] };
        },
      );

      cache.modify({
        id: cacheIdentifier,
        fields: {
          name(cachedName: string, context: any) {
            console.log({ cachedName });
            return 'updated field by cache.modify';
          },
        },
      });
      return null;
    },
  },
};

export default resolvers;
