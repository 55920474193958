import { InMemoryCache, defaultDataIdFromObject } from '@apollo/client';
import typePolicies from './typePolicies';
import generatedIntrospection from '@root/graphql/generated/fragmentIntrospection';

export const cache: InMemoryCache = new InMemoryCache({
  possibleTypes: generatedIntrospection.possibleTypes,
  typePolicies,
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      default:
        return defaultDataIdFromObject(responseObject);
    }
  },
});
