import { TypePolicy } from '@apollo/client';
import type { StrictTypedTypePolicies } from '@root/graphql/generated/apolloHelpers';

type StrictTypedTypePolicy = StrictTypedTypePolicies['Media'] & TypePolicy;

const Media: StrictTypedTypePolicy = {
  fields: {
    percentage: {
      read(existing, { cache }) {
        return existing;
      },
    },
  },
};

export { Media };
