import type { ReactiveVar } from '@apollo/client';
import { makeVar, useReactiveVar } from '@apollo/client';
import { IS_SERVER } from '@root/utils';

export const cartItemsVar = makeVar<Array<any>>([{ id: 0, name: 'hoang makeVar' }]);
export const isOpenListDictVar = makeVar<boolean>(false);

export const useCartItemsVar = () => useReactiveVar(cartItemsVar);
export const useIsOpenListDictVar = (): [boolean, ReactiveVar<boolean>] => [
  useReactiveVar(isOpenListDictVar),
  isOpenListDictVar,
];
