import type { NormalizedCacheObject } from '@apollo/client/cache';
import { Kind } from 'graphql';

import { ApolloClient, InMemoryCache, ApolloLink, HttpLink, concat, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';

import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

import { useMemo } from 'react';
import { cache } from './cache';
import { localTypeDefs } from '@root/graphql/localTypeDefs';
import * as constants from '@root/constants';
import { IS_SERVER, IS_DEV } from '@root/utils';
import resolvers from './resolvers';

const apiUrl = process.env.NEXT_PUBLIC_APOLLO_URI || 'http://localhost:4002';
const webSocketUri = process.env.NEXT_PUBLIC_APOLLO_WEBSOCKET_URI || `ws://localhost:4002/graphql`;

export let apolloClient: undefined | ApolloClient<NormalizedCacheObject>;

function getAuthentication() {
  const tempUserId = IS_SERVER ? '' : localStorage.getItem(constants.cookie.anonymous);
  const token = IS_SERVER ? '' : localStorage.getItem(constants.cookie.token);

  return { tempuserid: tempUserId || '', ...(token ? { authorization: 'Bearer ' + token } : {}) };
}

function createApolloLink(headers?: Record<any, any>): ApolloLink {
  const httpLink = new HttpLink({ uri: apiUrl });
  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext((context: Record<string, any>) => {
      return {
        headers: {
          ...headers,
          ...getAuthentication(),
        },
      };
    });

    return forward(operation);
  });

  const wsLink = IS_SERVER
    ? null
    : new GraphQLWsLink(
        createClient({
          url: webSocketUri,
          connectionParams: {
            ...headers,
            ...getAuthentication(),
          },
        }),
      );

  const authLink = concat(authMiddleware, httpLink);

  const splitLink =
    wsLink !== null
      ? split(
          ({ query }) => {
            const definition = getMainDefinition(query);
            return definition.kind === Kind.OPERATION_DEFINITION && definition.operation === 'subscription';
          },
          wsLink,
          authLink,
        )
      : authLink;

  return splitLink;
}

export function createApolloClient(headers?: Record<any, any>): ApolloClient<NormalizedCacheObject> {
  const client = new ApolloClient({
    ssrMode: IS_SERVER,
    cache: IS_SERVER ? new InMemoryCache() : cache,
    link: createApolloLink(headers),
    typeDefs: localTypeDefs,
    connectToDevTools: IS_DEV,
    resolvers,
  });

  return client;
}

export function initializeApollo(
  initialState: null | Record<any, any> = null,
  headers: Record<any, any> = {},
  shouldCreateNewInstance: boolean = false,
): ApolloClient<NormalizedCacheObject> {
  if (shouldCreateNewInstance && apolloClient) {
    apolloClient.stop();
  }

  const _apolloClient = shouldCreateNewInstance
    ? createApolloClient(headers)
    : apolloClient ?? createApolloClient(headers);

  if (initialState) {
    const existingCache = _apolloClient.extract();
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  // For SSG and SSR always create a new Apollo Client
  if (IS_SERVER) return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient || shouldCreateNewInstance) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState: Record<any, any> = {}): ApolloClient<NormalizedCacheObject> {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
