export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    IResponse: ['OnMuxAssetResponse', 'OnTranscodeResponse', 'OnTranscriptionResponse'],
  },
};
export default result;
